import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetComproitemsQuery } from './companyprofileApiSlice'
import { memo } from 'react'

const Comproitem = ({comproitemId, index}) => {
  const { comproitem } = useGetComproitemsQuery("comproitemsList", {
    selectFromResult: ({ data }) => ({
        comproitem: data?.entities[comproitemId]
    }),
  })

  const navigate = useNavigate()

  if (comproitem) {
    const created = new Date(comproitem.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

    const updated = new Date(comproitem.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

    const handleEdit = () => navigate(`/dash/homecompanyprofile/${comproitemId}`)

    return (
      <>
        <tr>
            <td>{index + 1}</td>
            {/* <td>{comproitem._id}</td> */}
            <td>{comproitem.section}</td>
            <td>{comproitem.subsection}</td>
            <td>{comproitem.value}</td>
            <td>{comproitem.value_1}</td>
            <td>{comproitem.value_2}</td>
            <td>{comproitem.value_3}</td>
            <td>{comproitem.description}</td>
            <td>{comproitem.order}</td>
            <td>{comproitem.status}</td>
            <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
        </tr>
      </>
    )    
  } else return null

}

const memoizedComproitem = memo(Comproitem)

export default memoizedComproitem