import React from 'react'

const 
HomeReactCode = () => {
  return (
    <div>
        Selamat datang di Home React Code Mudah Technology in Harmony
    </div>
  )
}

export default 
HomeReactCode