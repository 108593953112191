import React, { useEffect, useState } from 'react'

const Setting = ({pfanCriteria}) => {
  const [importInvestorDataStatus, setImportInvestorDataStatus] = useState('')
  const [importProjectsOwnerDataStatus, setProjectsOwnerDataStatus] = useState('')
  const [importProjectDataStatus, setImportProjectDataStatus] = useState('')
  const [databaseURIStatus, setDatabaseURIStatus] = useState('Failed to Read DB URI')

  useEffect (()=>{
    fetch('https://sdgfinhub-api-v2-1-3.onrender.com/settings')
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      console.log(data);
      setDatabaseURIStatus(data);
    });
  },[])
  
  const handleImportInvestorsData = async ()=>{
    const API_END_POINT = 'https://sdgfinhub-api-v2-1-3.onrender.com/import-investor/individually'
    // const API_END_POINT = 'https://sdgfinhub-api.onrender.com/import-investor/individually'

    try {
      const response = await fetch(API_END_POINT, {
        method: 'POST', // Method type
        headers: {
          // Content Type may need to be specified depending on the API requirement
          'Content-Type': 'application/json',
          // Add other headers here if needed
        },
        body: JSON.stringify({
          // Your request body content here
          key: 'value',
          // Add other request parameters here
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`); // Handle response errors
      }

      const responseData = await response.json(); // Parse JSON response
      setImportInvestorDataStatus(responseData.message); // Update state with the response data
    } catch (error) {
      console.error('Failed to fetch data:', error); // Handle or log error
    }
  };

  const handleImportProjectsOwnerData = async ()=> {

  }

  const handleImportProjectsData = async ()=> {
    
  }

  return (
    <main className='bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1'>
      <h1>Sync All Data</h1>
      {/* <InvestorSearch /> */}
      
      <div className='mt-3'>
        <table className='w-full text-gray-700'>
          <thead>
            <td>Data Set Id</td>
            <td>Data Set</td>
            <td>Sync</td>
            <td>Status</td>
          </thead>
          <tbody>
            <tr>
              <td>1001</td>
              <td>Investors Data</td>
              <td>
                <button
                  onClick={handleImportInvestorsData}
                >
                  Sync
                </button>
              </td>
              <td>{importInvestorDataStatus}</td>
            </tr>
            <tr>
              <td>1002</td>
              <td>Projects Owner Data</td>
              <td>
                <button
                  onClick={handleImportProjectsOwnerData}
                >
                  Sync
                </button>
              </td>
              <td>{importProjectsOwnerDataStatus}</td>
            </tr>
            <tr>
              <td>1003</td>
              <td>Projects Data</td>
              <td>
                <button
                  onClick={handleImportProjectsData}
                >
                  Sync
                </button>
              </td>
              <td>{importProjectDataStatus}</td>
            </tr>
            <tr>
              <td>1004</td>
              <td>Database URI</td>
              <td>
                {databaseURIStatus}
              </td>
              <td>Connected</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  )
}

export default Setting