import { useState, useEffect } from "react";
import { FaAlignLeft, FaMagnifyingGlass } from "react-icons/fa6";
import { Footer } from "flowbite-react";
import { BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";

const FunDemo = () => {
  // query all the post here
  const [banner6, setBanner6] = useState("/img/hero-image-2.jpg");
  const [banner7, setBanner7] = useState("/img/ads.png");
  const [logo, setLogo] = useState("img/MT 5.png");
  const [post, setPost] = useState([]);

  useEffect(() => {
    const apiUrl = "https://mudahtechcomproapi.onrender.com/post";
    fetch(apiUrl)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPost(data);
      });
  }, []);

  return (
    <main className="bg-neutralSilver max-w-screen-3xl min-h-screen h-screen">
      <header className="bg-neutralSilver sticky top-0 left-0 right-0 z-50">
        <div class="Logo w-[410px] h-[88px] flex mx-auto items-center text-brandDSecondary text-[64px] font-semibold ">
          MudahTech.
        </div>
        <nav class="sticky border-b-2 border-b-brandPrimary h-[55px] mx-[198px]">
          <ul className="flex justify-between gap-8 items-center text-base font-semibold md:flex space-x-12 text-brandPrimary hover:text-brandDSecondary hover:text-bold ">
            <li class="w-[51.95px] h-[54.63px] justify-center items-center inline-flex">
              <FaAlignLeft />
            </li>
            <li>Home</li>
            <li>Categories</li>
            <li>Features</li>
            <li class="w-[51.95px] h-[54.63px] justify-center items-center inline-flex">
              <FaMagnifyingGlass />
            </li>
          </ul>
        </nav>
      </header>

      <section class="flex flex-row">
        <div className="grid grid-cols-5 mx-[198px] mt-4">
          <div class="col-span-2 flex flex-col justify-around pr-4 font-semibold">
            <div>
              <h2 class="text-brandDSecondary text-base">News : AI</h2>
              <h2 class="text-sky-500 text-2xl ">
                New AI Algorithm Promises to Cut Energy Use in Data Centers
              </h2>
              <div className="flex flex-row gap-6 text-neutral-600 text-base">
                <div>Author : AI Generated</div>
                <div>Dateline : 12/04/2024 </div>
              </div>
            </div>

            <div className="flex flex-col gap-8 text-lg text-justify text-neutral-600 font-normal">
              <p>
                As global energy demands increase, the technology sector seeks
                sustainable solutions.
              </p>
              <p>
                GreenAI, an emerging tech company based in Boston, has unveiled
                a revolutionary AI algorithm designed to optimize energy
                consumption in data centers, one of the largest energy consumers
                in the technology industry.{" "}
              </p>
            </div>

            <div class="buttonNews w-32 text-center p-2 text-slate-100 text-base font-semibold ">
              Read More
            </div>
          </div>
          <img class="w-full h-[535px] col-span-3" src={banner6} />
        </div>
      </section>

      <section class="flex flex-row">
        <div className="grid grid-cols-5 mx-[198px] mt-4 gap-2">
          <div class="col-span-3 flex flex-col gap-0 justify-around font-semibold">
            <div className="grid grid-cols-3">
              <div className="col-span-1">
                <img className="w-full h-full" src={banner6} />
              </div>
              <div className="col-span-2 pl-2">
                <div>
                  <h2 class="text-brandDSecondary text-base">News : AI</h2>
                  <div class=" text-sky-500 text-2xl ">
                    AI to Revolutionize Early Cancer Detection, Claims New
                    Study"
                  </div>
                </div>

                <div className="flex flex-col gap-8 text-lg text-justify text-neutral-600 font-normal">
                  <p>
                    Personalized learning has been touted as the future of
                    education, and LearnSmart's new AI-driven platform makes
                    this a reality, adapting educational content to fit each
                    student's unique learning style.
                  </p>
                </div>

                <div class="buttonNews w-32 text-center p-2 text-slate-100 text-base font-semibold ">
                  Read More
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3">
              <div className="col-span-1">
                <img className="w-full h-full" src={banner6} />
              </div>
              <div className="col-span-2 pl-2">
                <div>
                  <h2 class="text-brandDSecondary text-base">News : AI</h2>
                  <div class=" text-sky-500 text-2xl ">
                    AI Customizes Learning Experiences, Boosting Student
                    Performance
                  </div>
                </div>

                <div className="flex flex-col gap-8 text-lg text-justify text-neutral-600 font-normal">
                  <p>
                    Early detection of cancer has long been a critical factor in
                    successful treatment outcomes. HealthTech AI's new system
                    uses advanced algorithms to analyze
                  </p>
                </div>

                <div class="buttonNews w-32 text-center p-2 text-slate-100 text-base font-semibold ">
                  Read More
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3">
              <div className="col-span-1">
                <img className="w-full h-full" src={banner6} />
              </div>

              <div className="col-span-2 pl-2">
                <div>
                  <h2 class="text-brandDSecondary text-base">
                    News : Software Development
                  </h2>
                  <div class=" text-sky-500 text-2xl ">
                    Self-Driving Cars: AI Improves Safety in Latest Tests
                  </div>
                </div>

                <div className="flex flex-col gap-8 text-lg text-justify text-neutral-600 font-normal">
                  <p>
                    As self-driving technology continues to evolve, AI has
                    become pivotal in ensuring safety. The latest tests show a
                    dramatic decrease in incidents, thanks to new AI
                    enhancements.
                  </p>
                </div>

                <div class="buttonNews w-32 text-center p-2 text-slate-100 text-base font-semibold ">
                  Read More
                </div>
              </div>
            </div>
          </div>

          <img
            class="col-span-2 w-[424px] h-[825px] flex justify-end"
            src={banner7}
          />
        </div>
      </section>

      {/* <div class="BannerRekomen w-[1074px] h-[330px] left-[152px] top-[1759px] absolute">
        <div class="Rectangle9 w-[1074px] h-[330px] left-0 top-0 absolute bg-neutral-600"></div>
        <div class="SoftwareDevelopment w-[288.17px] h-[55.01px] left-0 top-0 absolute text-center text-white text-2xl font-normal font-['Source Sans 3']">
          Software Development
        </div>
        <div class="Line1 w-[115.09px] h-[0px] left-[31.50px] top-[49.34px] absolute border-4 border-lime-600"></div>
      </div>
      <div class="Rekomen1 w-[334.50px] h-[251.24px] left-[167px] top-[1814px] absolute">
        <img
          class="HeroImage11 w-[334.50px] h-[154.53px] left-0 top-0 absolute"
          src="https://via.placeholder.com/335x155"
        />
        <div class="MajorCorporationsMigrateToReactJsForWebDevelopment w-[330.60px] h-[35.89px] left-[3.90px] top-[168.49px] absolute text-slate-100 text-xl font-normal font-['Source Sans 3']">
          Major Corporations Migrate to React JS for Web Development
        </div>
        <div class="Programming w-[172.61px] h-[26.92px] left-[1.95px] top-[224.32px] absolute text-brandDSecondary text-base font-semibold font-['Source Sans 3']">
          Programming
        </div>
      </div>
      <div class="Rekomendasi2 w-[335.48px] h-[249.25px] left-[521px] top-[1816px] absolute">
        <img
          class="Background1 w-[335.48px] h-[154.53px] left-0 top-0 absolute"
          src="https://via.placeholder.com/335x155"
        />
        <div class="ReactJsPowersNewWaveOfEnterpriseMobileApps w-[330.60px] h-[35.89px] left-[4.88px] top-[168.49px] absolute text-slate-100 text-xl font-normal font-['Source Sans 3']">
          React JS Powers New Wave of Enterprise Mobile Apps
        </div>
        <div class="Programming w-[172.61px] h-[26.92px] left-[4.88px] top-[222.33px] absolute text-brandDSecondary text-base font-semibold font-['Source Sans 3']">
          Programming
        </div>
      </div>
      <div class="Rekomendasi3 w-[334.50px] h-[249.25px] left-[876px] top-[1814px] absolute">
        <img
          class="Banner71 w-[334.50px] h-[154.53px] left-0 top-0 absolute"
          src="https://via.placeholder.com/335x155"
        />
        <div class="ReactJsVersion180ReleasedWithEnhancedFeatures w-[330.60px] h-[35.89px] left-0 top-[168.49px] absolute text-slate-100 text-xl font-normal font-['Source Sans 3']">
          React JS Version 18.0 Released with Enhanced Features
        </div>
        <div class="Programming w-[172.61px] h-[26.92px] left-0 top-[222.33px] absolute text-brandDSecondary text-base font-semibold font-['Source Sans 3']">
          Programming
        </div>
      </div> */}

      <footer className="flex sticky border-t-2 border-t-brandPrimary h-[55px] mx-[198px] mt-4 ">
        <div class="flex m-auto justify-center text-brandDSecondary text-lg font-base ">
          Copyright © 2024 PT. Mudah Technology Harmony
        </div>
      </footer>
    </main>
  );
};

export default FunDemo;
