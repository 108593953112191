import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'
import NotesList from './features/notes/NotesList'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
// import EditNote from './features/notes/EditNote'
// import NewNote from './features/notes/NewNote'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle';

import LayoutMain from './components/LayoutMain'
import LayoutPublic from './components/LayoutPublic';
import PageNotFound from './features/page_not_found/PageNotFound'
import Homeadmin from './features/homeadmin/Homeadmin'
import User from './features/users/User'
import Setting from './features/settings/Setting'
import Service from './components/Service';
import About from './components/About';
import TransactionChitose from './features/chitose/TransactionChitose';
import FunDemo from './components/FunDemo';
import HomeCompanyProfile from './features/companyprofile/HomeCompanyProfile'
import Post from './features/post_companyprofile/Post'
import CompanyProfile from './features/companyprofile/CompanyProfile'
import ComproitemsList from './features/companyprofile/ComproitemsList';
import EditComproitem from './features/companyprofile/EditComproitem';
import NewComproitem from './features/companyprofile/NewComproitem';
import PostReducerExample from './features/post_companyprofile/PostReducerExample'
import PostStateExample from './features/post_companyprofile/PostStateExample';
import HomeReactCode from './features/reactcode/HomeReactCode';
import { GiRoyalLove } from 'react-icons/gi';
import Homeiotintegration from './features/iotintegration//homeiotintegration/Homeiotintegration';
import AitoolsList from './features/aitools/AitoolsList';
import EditAitool from './features/aitools/EditAitool';
import NewAitool from './features/aitools/NewAitool';


function App() {
  useTitle('Mudah Technology Company Profile')
  const [companyProfileContent, setCompanyProfileContent] = useState([])

  //get dat from api during load
  useEffect(()=>{
    // call api company profile content
    const apiUrl = 'https://mudahtechcomproapi.onrender.com/companyprofilecontent'

    fetch(apiUrl)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCompanyProfileContent(data.filter((item)=>(item.status === "1")))
        // setCompanyProfileContent(data)
      })
  },[])


  return (
    <Routes>

    <Route path="/" element={<LayoutMain />}>
      {/* public routes */}
      <Route element={<LayoutPublic companyProfileContent={companyProfileContent} />} >
        <Route index element={<Public companyProfileContent={companyProfileContent} />} />
        <Route path="login" element={<Login />} />
        <Route path="service" element={<Service companyProfileContent={companyProfileContent} />} />
        <Route path="about" element={<About companyProfileContent={companyProfileContent} />} />
        <Route path="fundemo" element={<FunDemo />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
          <Route element={<Prefetch />}>
            <Route path="dash" element={<Layout />}>
              <Route index element={<Homeadmin/>} />
              <Route path="settings" element={<Setting  />} />
              <Route path="aitools">
                <Route index element={<AitoolsList />} />
                <Route path=":id" element={<EditAitool />} />
                <Route path="new" element={<NewAitool />} />
              </Route>
              <Route path="homecompanyprofile" >
                <Route index element={<HomeCompanyProfile />} />
                <Route path="companyprofile" element={<CompanyProfile companyProfileContent={companyProfileContent} />} />
                <Route path="companyprofilelist" element={<ComproitemsList companyProfileContent={companyProfileContent} />} />
                <Route path=":id" element={<EditComproitem />} />
                <Route path="new" element={<NewComproitem />} />
                <Route path="post" element={<Post />} />
                <Route path="postreducerexample" element={<PostReducerExample />} />
                <Route path="poststateexample" element={<PostStateExample />} />
              </Route>
              <Route path="homereactcode">
                <Route index element={<HomeReactCode />} />
              </Route>
              <Route path="chitose" element={<TransactionChitose  />} />
              <Route path="homeiotintegration">
                <Route index element={<Homeiotintegration />} />
              </Route>
              <Route path="users">
                <Route index element={<UsersList />} />
                <Route path=":id" element={<EditUser />} />
                <Route path="new" element={<NewUserForm />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>{/* End Dash */}
          </Route>

        </Route>
      
      </Route>{/* End Protected Routes */}
    
    </Route>

  </Routes>
  );
}

export default App;
