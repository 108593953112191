import { React, useEffect, useState } from "react";
// import banner6 from "../img/banner6.jpeg";

const About = ({ companyProfileContent }) => {
  const [banner6, setBanner6] = useState("/img/banner6.jpeg");
  const [aboutUs, setAboutUs] = useState([]);

  useEffect(() => {
    setAboutUs(
      companyProfileContent.filter(
        (item) => item.section === "ContactUs" && item.subsection === "aboutUs"
      )
    );
  }, [companyProfileContent]);

  return (
    <div className="bg-neutralSilver" id="about">
      <div className="px-4 py-4 lg:px-14 max-w-screen-2xl mx-auto my-8">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
          <div>
            <img src={banner6} alt="" />
          </div>
          <div className="md:w-3/5 mx-auto">
            <h2 className="text-4xl text-brandSecondary font-semibold mb-4 md:w-4/5">
              About Us
            </h2>
            <p className="md:w-full text-base font-medium text-neutralDGray mb-8 mx-1 text-justify">
              {/* To be at the forefront of the AI revolution, making advanced
              technology accessible and practical for businesses of all sizes.
              We envision a world where AI-driven solutions like ChatGPT become
              integral tools in every industry, empowering companies to achieve
              more with less effort. */}

              {aboutUs.map((item) => item.value)}
            </p>
            <button className="buttonPrimary">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
