import {useEffect, useState, useReducer} from 'react'

const PostReducerExample = () => {

  const initialState = {
    post: [],
    filteredPost: [],
    resetPost: []
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(()=>{
    const apiUrl = 'https://mudahtechcomproapi.onrender.com/post'
    fetch(apiUrl)
      .then((res)=>{
        return res.json()
      }).then((data)=> {
        dispatch({ type: 'SET_POSTS', payload: data });
      })

  }, [])


  function reducer(state, action) {
    switch (action.type) {
        case 'SET_POSTS':
            return { ...state, post: action.payload, resetPost: action.payload, filteredPost: action.payload };
        case 'FILTER_BY_CATEGORY':
            return { ...state, post: state.post.filter(item => item.category === 'Software Development') };
        case 'FILTER_BY_PUBLISHED':
            return { ...state, post: state.post.filter(item => item.ispublished === "TRUE") };
        case 'FILTER_BY_ORDER':
            return { ...state, filteredPost: state.post.filter(item => item.order > 8) };
        case 'DISPLAY_ORDER_FILTER':
            return { ...state, post: state.filteredPost };
        case 'RESET_POSTS':
            return { ...state, post: state.resetPost };
        default:
            throw new Error('Unknown action type');
    }
}

  // event Handler ada 4
  // Event handlers dispatch actions
  const handleFilterPostClick = () => dispatch({ type: 'FILTER_BY_CATEGORY' });
  const handleFilterIsPublishedPostClick = () => dispatch({ type: 'FILTER_BY_PUBLISHED' });
  const handleFilterOrder8PostClick = () => dispatch({ type: 'FILTER_BY_ORDER' });
  const handleTampilkanFilterOrder8PostClick = () => dispatch({ type: 'DISPLAY_ORDER_FILTER' });
  const handleResetPostClick = () => dispatch({ type: 'RESET_POSTS' });

  return (
    <div>
      Post Reducer Example  {state.post.length}
      <div>
        <div><button onClick={handleFilterPostClick}>Filter Category Post: Software Development!</button></div>
        <div><button onClick={handleFilterIsPublishedPostClick}>Filter ispublished: TRUE!</button></div>
        <div><button onClick={handleFilterOrder8PostClick}>Filter order lebih besar 8</button></div>
        <div><button onClick={handleTampilkanFilterOrder8PostClick}>Tampilkan hasil Filter order lebih besar 8</button></div>
        <div><button onClick={handleResetPostClick}>Reset Post!</button></div>
      </div>
      <table>
        <thead>
          <tr>
            <td>_id</td>
            <td>title</td>
            <td>lead</td>
            <td>teaser</td>
            <td>contentsection</td>
            <td>contentsubsection</td>
            <td>category</td>
            <td>keywords</td>
            <td>tags</td>
            <td>content</td>
            <td>dateline</td>
            <td>createddate</td>
            <td>modifieddate</td>
            <td>author</td>
            <td>editor</td>
            <td>modifiedby</td>
            <td>order</td>
            <td>ispublished</td>
            <td>isheadline</td>
            <td>status</td>
          </tr>
        </thead>
        <tbody>
          {state.post.map((items)=>(
            <tr>
              <td>{items._id}</td>
              <td>{items.title}</td>
              <td>{items.lead}</td>
              <td>{items.teaser}</td>
              <td>{items.contentsection}</td>
              <td>{items.contentsubsection}</td>
              <td>{items.category}</td>
              <td>{items.keywords}</td>
              <td>{items.tags}</td>
              <td>{items.content}</td>
              <td>{items.dateline}</td>
              <td>{items.createddate}</td>
              <td>{items.modifieddate}</td>
              <td>{items.author}</td>
              <td>{items.editor}</td>
              <td>{items.modifiedby}</td>
              <td>{items.order}</td>
              <td>{items.ispublished}</td>
              <td>{items.isheadline}</td>
              <td>{items.status}</td>
            </tr>                          
          ))}                        
        </tbody>
      </table>
    </div>
  )
}

export default PostReducerExample