import {React, useState, useEffect} from 'react'

const Companyprofile = ({ companyProfileContent }) => {
    const [companyProfileContentLoad, setCompanyProfileContentLoad] = useState([]);

    useEffect(() => {
      setCompanyProfileContentLoad(
        companyProfileContent
      );
  
    }, [companyProfileContent]);
  
    return (
      <div>
        Company Profile data
        <table>
          <thead>
            <tr>
              <td>_id</td>
              <td>section</td>
              <td>subsection</td>
              <td>value</td>
              <td>value_1</td>
              <td>value_2</td>
              <td>value_3</td>
              <td>order</td>
              <td>status</td>
            </tr>
          </thead>
          <tbody>
            {
              companyProfileContentLoad.map((items) => (
                <tr>
                  <td>{items._id}</td>
                  <td>{items.section}</td>
                  <td>{items.subsection}</td>
                  <td>{items.value}</td>
                  <td>{items.value_1}</td>
                  <td>{items.value_2}</td>
                  <td>{items.value_3}</td>
                  <td>{items.order}</td>
                  <td>{items.status}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
}

export default Companyprofile