import {useEffect, useState} from 'react'


const PostStateExample = () => {
  // query all the post here
  const [post, setPost] = useState([])
  const [filteredPost, setFilteredPost] = useState([])
  const [resetPost, setResetPost] = useState([])

  useEffect(()=>{
    const apiUrl = 'https://mudahtechcomproapi.onrender.com/post'
    fetch(apiUrl)
      .then((res)=>{
        return res.json()
      }).then((data)=> {
        setPost(data)
        setResetPost(data)
      })

  }, [])

  // Event Handler ada 4
  const handleFilterPostClick = ()=>(
    // setFilteredPost(post.filter((item)=>(item.contentsection === 'news')))
    setPost(post.filter((item)=>(item.category === 'Software Development')))
  )

  const handleFilterIsPublishedPostClick = ()=>(
    // setFilteredPost(post.filter((item)=>(item.contentsection === 'news')))
    setPost(post.filter((item)=>(item.ispublished === "TRUE")))
  )

  const handleFilterOrder8PostClick = ()=>{
        setFilteredPost(post.filter((item)=>(item.order > 8)))
        setPost(filteredPost)

    }
  const handleTampilkanFilterOrder8PostClick = ()=>{
        // setFilteredPost(post.filter((item)=>(item.order > 8)))
        setPost(filteredPost)
    }

  const handleResetPostClick = ()=>(
    setPost(resetPost)
  )

  return (
    <div>
      Post State Example {post.length}
      <div>
        <button
          onClick={handleFilterPostClick}  
        >
          Filter Category Post: Software Development!
        </button>
      </div>
      <div>
        <button
          onClick={handleFilterIsPublishedPostClick}  
        >
          Filter ispublished: TRUE!
        </button>
      </div>
      <div>
        <button
          onClick={handleFilterOrder8PostClick}  
        >
          Filter order lebih besar 8
        </button>
      </div>
      <div>
        <button
          onClick={handleTampilkanFilterOrder8PostClick}  
        >
          Tampilkan hasil Filter order lebih besar  8
        </button>
      </div>
      <div>
        <button
          onClick={handleResetPostClick}  
        >
          Reset Post!
        </button>
      </div>
      <table>
        <thead>
        <tr>
            <td>_id</td>
            <td>title</td>
            <td>lead</td>
            {/* <td>teaser</td> */}
            <td>contentsection</td>
            <td>contentsubsection</td>
            <td>category</td>
            <td>keywords</td>
            <td>tags</td>
            {/* <td>content</td> */}
            <td>dateline</td>
            <td>createddate</td>
            <td>modifieddate</td>
            <td>author</td>
            <td>editor</td>
            <td>modifiedby</td>
            <td>order</td>
            <td>ispublished</td>
            <td>isheadline</td>
            <td>status</td>
        </tr>
        </thead>
        <tbody>
        {post.map((items)=>(
            <tr>
            <td>{items._id}</td>
            <td>{items.title}</td>
            <td>{items.lead}</td>
            {/* <td>{items.teaser}</td> */}
            <td>{items.contentsection}</td>
            <td>{items.contentsubsection}</td>
            <td>{items.category}</td>
            <td>{items.keywords}</td>
            <td>{items.tags}</td>
            {/* <td>{items.content}</td> */}
            <td>{items.dateline}</td>
            <td>{items.createddate}</td>
            <td>{items.modifieddate}</td>
            <td>{items.author}</td>
            <td>{items.editor}</td>
            <td>{items.modifiedby}</td>
            <td>{items.order}</td>
            <td>{items.ispublished}</td>
            <td>{items.isheadline}</td>
            <td>{items.status}</td>
            </tr>                          
        ))}                        
        </tbody>
    </table>
    </div>
  )
}

export default PostStateExample