import React from 'react'

const Homeiotintegration = () => {
    const menuItems = [
        { label: 'Sensors', icon: '🔍' },
        { label: 'IoT Protocols', icon: '📡' },
        { label: 'Use Case Solution Generator', icon: '⚙️' },
        { label: 'AI Prompt Collections', icon: '🤖' },
        { label: 'AI Power Prompt', icon: '⚡' },
        { label: 'Extra', icon: '➕' }
    ];

    return (
        <main>
            <div className="bg-blue-400 text-gray-900 font-bold p-4 flex justify-between items-center">
                <header className="text-xl font-semibold text-white">
                    AI IoT Integration
                </header>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-4">
                {menuItems.map((item, index) => (
                    <div key={index} className="bg-gradient-to-r from-blue-500 to-green-500 text-white p-6 rounded-lg shadow-md flex items-center justify-center">
                        <div className="flex flex-col items-center">
                            <div className="icon-container mb-2">
                                {/* Replace this with actual icons */}
                                <span className="text-3xl">{item.icon}</span>
                            </div>
                            <div className="text-center text-lg font-semibold">
                                {item.label}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </main>
    )
}

export default Homeiotintegration
