import { useGetAitoolsQuery } from "./aitoolsApiSlice"
import Aitool from "./Aitool"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const AitoolsList = () => {
    useTitle('techAitools: Aitools List')

    const { username, isManager, isAdmin } = useAuth()

    const {
        data: aitools,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAitoolsQuery('aitoolsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = aitools

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(aitoolId => entities[aitoolId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(aitoolId => <Aitool key={aitoolId} aitoolId={aitoolId} />)

        content = (
            <>
                <div className="mb-2 pl-4 pt-2 pb-2 text-lg bg-blue-400 text-gray-900 font-bold">
                <strong className='text-gray-700'>AI Tools List ({filteredIds.length})</strong>
                {console.log(filteredIds)}
                </div>
                <table className="table table--aitools">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th aitool__status">Username</th>
                            <th scope="col" className="table__th aitool__created">Title</th>
                            <th scope="col" className="table__th aitool__updated">Ai Prompt</th>
                            <th scope="col" className="table__th aitool__title">Content</th>
                            <th scope="col" className="table__th aitool__username">Last Response</th>
                            <th scope="col" className="table__th aitool__edit">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </>
        )
    }

    return content
}
export default AitoolsList