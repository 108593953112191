import {React, useState, useEffect} from 'react'

const Post = () => {
  // query all the post here
  const [post, setPost] = useState([])

  useEffect(()=>{
    const apiUrl = 'https://mudahtechcomproapi.onrender.com/post'
    fetch(apiUrl)
      .then((res)=>{
        return res.json()
      }).then((data)=> {
        setPost(data)
      })

  }, [])

  return (
    <section>
        <main className="bg-neutralSilver">
            <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto min-h-screen h-screen">
                <div className="mt-6 md:px-14 px-4 py-8 max-w-screen-2xl mx-auto">
                    Post Management
                    <table>
                      <thead>
                        <tr>
                          <td>_id</td>
                          <td>title</td>
                          <td>lead</td>
                          <td>teaser</td>
                          <td>contentsection</td>
                          <td>contentsubsection</td>
                          <td>category</td>
                          <td>keywords</td>
                          <td>tags</td>
                          <td>content</td>
                          <td>dateline</td>
                          <td>createddate</td>
                          <td>modifieddate</td>
                          <td>author</td>
                          <td>editor</td>
                          <td>modifiedby</td>
                          <td>order</td>
                          <td>ispublished</td>
                          <td>isheadline</td>
                          <td>status</td>
                        </tr>
                      </thead>
                      <tbody>
                        {post.map((items)=>(
                          <tr>
                            <td>{items._id}</td>
                            <td>{items.title}</td>
                            <td>{items.lead}</td>
                            <td>{items.teaser}</td>
                            <td>{items.contentsection}</td>
                            <td>{items.contentsubsection}</td>
                            <td>{items.category}</td>
                            <td>{items.keywords}</td>
                            <td>{items.tags}</td>
                            <td>{items.content}</td>
                            <td>{items.dateline}</td>
                            <td>{items.createddate}</td>
                            <td>{items.modifieddate}</td>
                            <td>{items.author}</td>
                            <td>{items.editor}</td>
                            <td>{items.modifiedby}</td>
                            <td>{items.order}</td>
                            <td>{items.ispublished}</td>
                            <td>{items.isheadline}</td>
                            <td>{items.status}</td>
                          </tr>                          
                        ))}                        
                      </tbody>
                    </table>
                </div>
            </div>
        </main>        
    </section>
  )

}

export default Post