import { React, useEffect, useState } from "react";
import { FaWikipediaW } from "react-icons/fa6";
// import comp1 from "../../public/img/unilever.png";
// import comp2 from "../../public/img/bappenas.png";

// import card1 from "../../";
// import helpmate from "../../public/img/helpmate.png";
// import card3 from "../../public/card 2.jpg";

const Service = ({ companyProfileContent }) => {
  const [comp1, setComp1] = useState("/img/js.png");
  const [comp2, setComp2] = useState("/img/html.png");
  const [comp3, setComp3] = useState("/img/css.png");
  const [comp4, setComp4] = useState("/img/node.png");
  const [comp5, setComp5] = useState("/img/mongo.png");
  const [comp6, setComp6] = useState("/img/phyton.png");
  const [services, setServices] = useState([]);
  const [bannerTitle, setBannerTitle] = useState([]);
  const [bannerLogo, setBannerLogo] = useState([]);
  const [bannerQuote, setBannerQuote] = useState([]);

  useEffect(() => {
    setServices(
      companyProfileContent.filter(
        (items) =>
          items.section === "ProductService" && items.subsection === "product"
      )
    );

    setBannerTitle(
      companyProfileContent.filter(
        (items) =>
          items.section === "servicebanner" &&
          items.subsection === "bannertitle"
      )
    );

    setBannerLogo(
      companyProfileContent.filter(
        (items) =>
          items.section === "servicebanner" && items.subsection === "bannerlogo"
      )
    );

    setBannerQuote(
      companyProfileContent.filter(
        (items) =>
          items.section === "servicebanner" &&
          items.subsection === "bannerquote"
      )
    );
  }, [companyProfileContent]);

  return (
    <div className="md:px-14 px-4 py-8 max-w-screen-2xl mx-auto" id="service">
      <div id="service" className="text-center my-8">
        <h2 className="text-4xl text-brandSecondary font-semibold mb-2">
          {bannerTitle.map((items) => items.value)}
        </h2>

        {/* company logo */}
        <div className="my-12 flex flex-wrap justify-between items-center md:gap-8 gap-2">
          {bannerLogo.map((items) => (
            <img src={items.value} alt="" className="md:w-12 w-8" />
          ))}
        </div>
      </div>

      {/* service card */}
      <div className="mt-20 md:w-1/2 mx-auto text-center">
        {bannerQuote.map((items) => (
          <h2 className="text-4xl text-neutralDGray font-semibold mb-2">
            {items.value}{" "}
            <span className="text-brandPrimary">{items.value_1}</span>
          </h2>
        ))}
      </div>

      {/* cards */}
      <div className="mt-14 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:w-11/12 mx-auto gap-12">
        {services.map((service) => (
          <div
            key={service._id}
            className="px-4 py-8 text-center md:w-fullnpms  mx-auto md:h-[450px] rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-brandPrimary transition-all duration-300 flex flex-col items-center justify-center h-full "
          >
            <div>
              <img
                className="bg-[#e8f5e9] h-14 w-14 mx-auto mb-2 rounded-tl-3xl rounded-br-3xl"
                src={service.value_2}
                alt=""
              />
            </div>
            <h4 className="text-2xl font-bold text-neutralDGray mb-2 px-2">
              {service.value}
            </h4>
            <p className="text-base font-medium text-neutralDGray">
              {service.value_1}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
