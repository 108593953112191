import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const aitoolsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = aitoolsAdapter.getInitialState()

export const aitoolsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAitools: builder.query({
            query: () => ({
                url: '/aitools',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedAitools = responseData.map(aitool => {
                    aitool.id = aitool._id
                    return aitool
                });
                return aitoolsAdapter.setAll(initialState, loadedAitools)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Aitool', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Aitool', id }))
                    ]
                } else return [{ type: 'Aitool', id: 'LIST' }]
            }
        }),
        addNewAitool: builder.mutation({
            query: initialAitool => ({
                url: '/aitools',
                method: 'POST',
                body: {
                    ...initialAitool,
                }
            }),
            invalidatesTags: [
                { type: 'Aitool', id: "LIST" }
            ]
        }),
        updateAitool: builder.mutation({
            query: initialAitool => ({
                url: '/aitools',
                method: 'PATCH',
                body: {
                    ...initialAitool,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Aitool', id: arg.id }
            ]
        }),
        getLastresponseValue: builder.mutation({
            query: ({ reqAiPrompt, reqContent, reqWeight }) => ({
                url: '/gptmodel/gpt35',
                method: 'GET',
                body: { reqAiPrompt, reqContent, reqWeight }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Transformroundtwoaiscoringsheet', id: arg.id }
            ]
        }),
        deleteAitool: builder.mutation({
            query: ({ id }) => ({
                url: `/aitools`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Aitool', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetAitoolsQuery,
    useAddNewAitoolMutation,
    useUpdateAitoolMutation,
    useGetLastresponseValueMutation,
    useDeleteAitoolMutation,
} = aitoolsApiSlice

// returns the query result object
export const selectAitoolsResult = aitoolsApiSlice.endpoints.getAitools.select()

// creates memoized selector
const selectAitoolsData = createSelector(
    selectAitoolsResult,
    aitoolsResult => aitoolsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAitools,
    selectById: selectAitoolById,
    selectIds: selectAitoolIds
    // Pass in a selector that returns the aitools slice of state
} = aitoolsAdapter.getSelectors(state => selectAitoolsData(state) ?? initialState)