import React, { useState } from 'react'

const TransactionChitose = () => {
    const [predataRowsNumber, setPredataRowsNumber] = useState('')
    const [predataTopTen, setPredataTopTen] = useState([])

    const apiURI = 'https://mudahtechcomproapi.onrender.com/chitose'

    const getPredataRowsNumber = () => fetch(apiURI)
        .then((res)=>{
            return res.json();
        })
        .then((data)=>{
            setPredataRowsNumber(data.recordsetnumber)
        })
    
    const apiURITopTen = 'https://mudahtechcomproapi.onrender.com/chitose/recordset'
    
    const getPredataTopTen = () => fetch(apiURITopTen)
        .then((res)=>{
            return res.json();
        })
        .then((data)=>{
            setPredataTopTen(data.result.recordsets[0])
        })

    return (
    <div>
        <div>
            Transaction Chitose
        </div>
        <div>
            <button
                className="bg-blue-500"
                onClick={()=> getPredataRowsNumber()}
            >
                Connect and Get
            </button>
             Jumlah Rows pre_data: {predataRowsNumber}
        </div>

        <div>
            <button
                className="bg-blue-500"
                onClick={()=> getPredataTopTen()}
            >
                Load Data Top Ten
            </button>
        </div>

        <div>
            Predata Table Top Ten
            <table className='w-full text-gray-700'>
                <thead>
                    <tr>
                        <th>
                            No.
                        </th>
                        <th>
                            customer_category
                        </th>
                        <th>
                            customer_detail
                        </th>
                        <th>
                            unit_price
                        </th>
                        <th>
                            net_sales
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {predataTopTen.map((item, index)=>(
                        <tr>
                            <td>
                                {index + 1}
                            </td>
                            <td>
                                {item.customer_category}
                            </td>
                            <td>
                                {item.customer_detail}
                            </td>
                            <td>
                                {item.unit_price}
                            </td>
                            <td>
                                {item.net_sales}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default TransactionChitose