import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetAitoolsQuery } from './aitoolsApiSlice'
import { memo } from 'react'

const Aitool = ({ aitoolId }) => {

    const { aitool } = useGetAitoolsQuery("aitoolsList", {
        selectFromResult: ({ data }) => ({
            aitool: data?.entities[aitoolId]
        }),
    })

    const navigate = useNavigate()

    if (aitool) {
        const created = new Date(aitool.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const updated = new Date(aitool.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dash/aitools/${aitoolId}`)

        return (
            <tr className="table__row">
                <td className="table__cell aitool__username">{aitool.username}</td>
                <td className="table__cell aitool__title">{aitool.title}</td>
                <td className="table__cell aitool__updated">{aitool.aiprompt}</td>
                <td className="table__cell aitool__created">{aitool.content}</td>
                <td className="table__cell aitool__created">{aitool.lastresponse}</td>


                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedAitool = memo(Aitool)

export default memoizedAitool