import { Carousel } from "flowbite-react";
import { React, useEffect, useState } from "react";
import PublicHeader from "./PublicHeader";
import Service from "./Service";
import About from "./About";
import PublicFooter3 from "./PublicFooter3";

const Public = ({ companyProfileContent }) => {
  // const [banner, setBanner] = useState('img/banner3.jpeg')
  // const [banner2, setBanner2] = useState('img/banner5.jpeg')
  const [banner3, setBanner3] = useState("/img/helpmate3.png");

  const [heroOverlayText, setHeroOverlayText] = useState([]);
  const [productServiceProduct, setProductServiceProduct] = useState([]);

  useEffect(() => {
    // setHeroOverlayText(
    //   companyProfileContent.filter(
    //     (item) => item.section === "hero" && item.subsection === "overlaytext"
    //   )
    // );

    // Function to shuffle an array
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
      }
      return array;
    };

    // First, filter the array based on the conditions
    const filteredArray = companyProfileContent.filter(
      (item) => item.section === "hero" && item.subsection === "overlaytext"
    );

    // Shuffle the filtered array and take the first three items
    const randomSelected = shuffleArray(filteredArray).slice(0, 3);

    // Set the state with the randomly picked three items
    setHeroOverlayText(randomSelected);

    setProductServiceProduct(
      companyProfileContent.filter(
        (item) =>
          item.section === "ProductService" && item.subsection === "product"
      )
    );
  }, [companyProfileContent]);

  const content = (
    <section>
      {/* header, publicHeader.js */}
      <PublicHeader companyProfileContent={companyProfileContent} />

      {/* hero */}
      <main className="bg-neutralSilver" id="home">
        <div className="px-4 lg:px-0 max-w-screen-3xl mx-auto min-h-screen h-screen ">
          <Carousel
            slideInterval={10000}
            pauseOnHover
            className="w-screen-xl mx-auto"
          >
            {heroOverlayText.map((item) => (
              <div
                className="my-8 md:my-8 py-12 md:h-[850px] h-full w-full flex flex-col md:flex-row-reverse items-center justify-center"
                // className="my-28 md:my-8 py-12 flex h-[850px]  flex-col md:flex-row-reverse items-end justify-between gap-12"
                style={{
                  backgroundImage: `url(${item.value_1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <div>
                  <img className="" src={item.value_1} alt="" />
                </div> */}
                <div className="flex flex-col text-center mx-auto bg-opacity-40 bg-neutralDGray">
                  {/* <h2 className="md:text-8xl text-2xl font-semibold mb-4 text-brandPrimary md:w-3/4 md:leading-normal">
                    We are{" "}
                    <span className="text-brandPrimary">
                      Mudah Technology in Harmony
                    </span>{" "}
                  </h2> */}
                  <h2 className="md:text-6xl md:text-center text-5xl text-right font-medium px-10 py-4 text-white  md:w-full md:leading-normal yanone-kaffeesatz">
                    "{item.value}"
                  </h2>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </main>

      {/* Service */}
      <Service companyProfileContent={companyProfileContent} />

      {/* Our Vision */}
      <About companyProfileContent={companyProfileContent} />

      {/* footer */}
      <PublicFooter3 />
    </section>
  );
  return content;
};
export default Public;
