import React from 'react'

const PageNotFound = () => {
  return (
    <main className='main-container'>
      <h1>Page Not Found. Please contact your admin</h1>
    </main>
  )
}

export default PageNotFound