import {React, useState, useEffect} from 'react'

import { useGetComproitemsQuery } from "./companyprofileApiSlice"
import Comproitem from './Comproitem'
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileCirclePlus,
    faFilePen,
    faUserGear,
    faUserPlus,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'

const ComproitemsList = () => {
    const { username, isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    const onNewComproitemClicked = () => navigate('/dash/homecompanyprofile/new')

    const {
        data: comproitems,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetComproitemsQuery('comproitemsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = comproitems

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            // Here only data which belong to username. Which in this case is irrelevant
            // filteredIds = ids.filter(comproitemId => entities[comproitemId].username === username)
            filteredIds = [...ids]
        }


        // const tableContent = companyProfileContentLoad.map((item, index)=>(<Comproitem item={item} index={index}/>))
        const tableContent = ids?.length && filteredIds.map((comproitemId, index) => <Comproitem key={comproitemId} comproitemId={comproitemId} index={index} />)

        content = (
            <>
                <div className='pt-2 pb-4 bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200'>
                    <button
                        className="bg-green-500"
                        title="New Company Profile Item data"
                        onClick={onNewComproitemClicked}
                    >
                        <FontAwesomeIcon icon={faFileCirclePlus} /> New Company Profile Item
                    </button>
                </div>
                Company Profile Data v2
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            {/* <th>_id</th> */}
                            <th>section</th>
                            <th>subsection</th>
                            <th>value</th>
                            <th>value_1</th>
                            <th>value_2</th>
                            <th>value_3</th>
                            <th>description</th>
                            <th>order</th>
                            <th>status</th>
                            <th>EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </>
        )
    }

    return content

}
export default ComproitemsList