import {
  BsCart3,
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
} from "react-icons/bs";
import {
  FaChartPie,
  FaCommentDollar,
  FaIndustry,
  FaCoins,
  FaSignOutAlt,
} from "react-icons/fa";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import { FaListCheck } from "react-icons/fa6";
import { TbChecklist } from "react-icons/tb";
import { MdFiberNew } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import useAuth from "../hooks/useAuth";

// start . to be used with log out button
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
// end . to be used with log out button

const linkClasses =
  "flex items-center gap-4 font-light px-1 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-white";
const linkClassesBadge =
  "flex items-center gap-0.5 font-light text-base text-white";
const linkClassesNew =
  "flex items-center gap-8 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-black ";
const linkClassesHeader =
  "flex items-center gap-8 font-light px-3 py-2 mt-4 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-white";

const Sidebar = ({
  isOpen,
  toggleSidebar, // Receive the new props
  projectsData,
  projectOwnersData,
  investorsData,
  transformProjectsData,
}) => {
  // User Utility
  const { username, displayname, isManager, isAdmin, isSdgFinHubCurator } =
    useAuth();

  // Start . to be used with log out button
  const navigate = useNavigate();

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);
  // End . to be used with log out button

  return (
    <div
      className="bg-green-900 flex flex-col h-full p-3 text-white fixed top-0 left-0 z-50"
      style={{ width: isOpen ? "16rem" : "3rem" }}
    >
      {" "}
      {/* Adjust the width here */}
      {/* <div className="flex flex-col justify-between h-full">
        <div className={`p-2 ${isOpen ? 'self-end' : 'self-center'} transition-all duration-300`}> */}
      <div className="relative flex flex-col h-full">
        {/* Position the toggle button absolutely */}
        <div className="absolute top-0 right-0 -mt-5.5">
          {/* Menu Icon or Toggle Button */}
          <button onClick={toggleSidebar} className="p-2">
            {/* { isOpen && <GoSidebarCollapse  fontSize={15} />} */}
            {isOpen ? (
              <GoSidebarExpand fontSize={15} />
            ) : (
              <GoSidebarCollapse fontSize={15} />
            )}
          </button>
        </div>

        {isOpen && (
          <>
            <div className="flex items-center gap-2 px-2 py-3 text-white border-b border-b-gray-400">
              <FaCoins fontSize={24} />
              <span className="text-neutral-100 text-lg">
                Mudah Technology 1.0.20240416.1832
              </span>
            </div>

            {/* <div className="px-2 py-3 mb-3 border-b border-b-gray-400"> */}
            {/* <div className="px-2 py-3 mb-3 border-b border-b-gray-400 border border-red-500 text-lg sm:text-base"> */}
            <div className="px-2 py-3 mb-3 border-b border-b-gray-400 text-base">
              Welcome, {displayname}!
            </div>

            <div className="flex py-4 flex-col gap-0.5 overflow-y-scroll scrollbar-webkit">
              <ul>
                <li className={linkClasses}>
                  <span className="text-lg">Home</span>
                </li>
                <li className={linkClasses}>
                  <BsGrid1X2Fill className="text-lg" />{" "}
                  <span className="text-md">
                    <NavLink to="/dash">Home</NavLink>
                  </span>
                </li>

                {/* Group Menu Company Profile */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Company Profile App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile" className="w-32">
                      Control Panel
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/companyprofilelist" className="w-32">
                      Company Profile
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/post" className="w-32">
                      Posts
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Mudah AI Tools</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/aitools" className="w-32">
                      Ask AI Any
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu Chitose */}
                <li className={linkClassesHeader}>
                  <span className="text-md">Chitose App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/chitose" className="w-32">
                      Import Excels
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {/* Group Menu */}
                <li className={linkClassesHeader}>
                  <span className="text-md">SDG Fin Hub App</span>
                </li>

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <FaChartPie className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/analytics">Go to the app!</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">IoT & Integration</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/homeiotintegration">
                        IoT & Integration
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">MERN</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/homereactcode">
                        REACT 18
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/postreducerexample" className="w-32">
                      Post useReducer Example
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsFillArchiveFill className="text-lg" />{" "}
                    <NavLink to="/dash/homecompanyprofile/poststateexample" className="w-32">
                      Post useState Example
                    </NavLink>
                    <span className="text-xs font-bold bg-teal-600 px-1 rounded-md w-7 text-center">
                      {/* {projectsData.length} */}
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        MongoDB Atlas
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        Node JS
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <TbChecklist className="text-lg" />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/scoringscheme">
                        Tailwind CSS & CSS
                      </NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClassesHeader}>
                    <span className="text-md">Settings</span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <FaChartPie />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/settings">API settings</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                  <li className={linkClasses}>
                    <BsPeopleFill />{" "}
                    <span className="text-md">
                      <NavLink to="/dash/boardcurator">Help & Supports</NavLink>
                    </span>
                  </li>
                )}

                {(isManager || isAdmin) && (
                    <li className={linkClasses}>
                      <BsPeopleFill />{" "}
                      <span className="text-md">
                        <Link style={{ color:"yellow",  }} to="/dash/users">View User Settings</Link>
                      </span>
                    </li>
                )}

                <li>
                  {/* <FaSignOutAlt className="text-lg" />{" "} */}
                  <span className="flex mt-4 ml-4 text-md">
                    <button onClick={sendLogout}>Sign Out</button>
                  </span>
                </li>
              </ul>
              <div className="mt-10 text-gray-600">
                <small><i>AI can make mistakes. Consider checking important information</i></small>
                <small><i>AI can make mistakes. Consider checking important information</i></small>
              </div>

            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
