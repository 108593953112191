import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewComproitemMutation } from "./companyprofileApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewComproitemForm = ({ users }) => {

    const [addNewComproitem, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewComproitemMutation()

    const navigate = useNavigate()

    const [section, setSection] = useState('')
    const [subsection, setSubsection] = useState('')
    const [value, setValue] = useState('')
    const [value_1, setValue_1] = useState('')
    const [value_2, setValue_2] = useState('')
    const [description, setDescription] = useState('')
    const [order, setOrder] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setSection('')
            setSubsection('')
            setValue('')
            setValue_1('')
            setOrder('')
            setStatus('')
            navigate('/dash/homecompanyprofile/companyprofilelist')
        }
    }, [isSuccess, navigate])

    const onSectionChanged = e => setSection(e.target.value)
    const onSubsectionChanged = e => setSubsection(e.target.value)
    const onValueChanged = e => setValue(e.target.value)
    const onValue_1Changed = e => setValue_1(e.target.value)
    const onValue_2Changed = e => setValue_2(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onOrderChanged = e => setOrder(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)

    const canSave = [section, subsection, value, value_1, order, status].every(Boolean) && !isLoading

    const onSaveComproitemClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewComproitem({ section, subsection, value, value_1, value_2, order, status })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !section ? "form__input--incomplete" : ''
    const validTextClass = !subsection ? "form__input--incomplete" : ''

    const content = (
        <main className='bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1'>
            <p className={errClass}>{error?.data?.message}</p>

            <form onSubmit={onSaveComproitemClicked}>
                <div>
                    {/* <h2>Edit Comproitem #{comproitem.ticket}</h2> */}
                    <div>
                        <button
                            type="button"
                            className="bg-green-500"
                            title="Save"
                            onClick={onSaveComproitemClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> SAVE
                        </button>
                    </div>
                </div>
                <table className='w-full text-gray-700'>
                    <tbody>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-title">
                                    section:
                                </label>
                            </td>
                            <td>
                                <input
                                    className={`form__input ${validTitleClass}`}
                                    id="comproitem-title"
                                    name="title"
                                    type="text"
                                    autoComplete="off"
                                    value={section}
                                    onChange={onSectionChanged}
                                />                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    subsection:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="text"
                                    value={subsection}
                                    onChange={onSubsectionChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    Value:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value"
                                    value={value}
                                    onChange={onValueChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    value_1:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value_1"
                                    value={value_1}
                                    onChange={onValue_1Changed}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    value_2:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value_2"
                                    value={value_2}
                                    onChange={onValue_2Changed}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                description:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="description"
                                    value={description}
                                    onChange={onDescriptionChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    order:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="order"
                                    value={order}
                                    onChange={onOrderChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    status:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="text"
                                    value={status}
                                    onChange={onStatusChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    {/* <h2>Edit Comproitem #{comproitem.ticket}</h2> */}
                    <div>
                        <button
                            type="button"
                            className="bg-green-500"
                            title="Save"
                            onClick={onSaveComproitemClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> SAVE
                        </button>
                    </div>
                </div>
            </form>
        </main>
    )

    return content
}

export default NewComproitemForm