import React from 'react'
import {
  BsCart3,
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
} from "react-icons/bs";
import {
  FaChartPie,
  FaCommentDollar,
  FaIndustry,
  FaCoins,
  FaSignOutAlt,
} from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";

const HomeCompanyProfile = () => {
  const linkClasses = "flex items-center gap-4 px-1 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base text-blue";

  return (
    <main className='bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1'>
      <h1>Selamat datang di Company Profile Mudah Technology</h1>
      
      <p className='pb-4 pt-4'> Di sini adalah Control Panel untuk mengatur content dari Company Profile Anda</p>

      <p className='pb-4 pt-4'>Mari mulai!</p>

      <ul>
        <li className={linkClasses}>
          <FaIndustry className="text-lg" />{" "}
          <Link to="https://mudahtechcompro.onrender.com/">
            Company Profile Website
          </Link>
        </li>
        <li className={linkClasses}>
          <FaCommentDollar className="text-lg" />{" "}
          <NavLink to="/dash/homecompanyprofile/companyprofilelist">
            Company Profile
          </NavLink>
        </li>
          <li className={linkClasses}>
            <FaChartPie className="text-lg" />{" "}
            <NavLink to="/dash/homecompanyprofile/post">
              Posts
            </NavLink>
          </li>
      </ul>
      <div className='pt-10'>
        Copyright 2024 Mudah Technology in Harmony
      </div>
    </main>
  )
}

export default HomeCompanyProfile