import { useEffect, useState, React } from "react";
// import logo from "../img/MT 5.png";
import { Link as ScrollLink } from "react-scroll";
import { FaXmark, FaBars } from "react-icons/fa6";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const PublicHeader = ({ companyProfileContent }) => {
  const [logo, setLogo] = useState("img/MT 5.png");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setisSticky] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const [navFunDemo, setNavFunDemo] = useState([]);

  // set toggle menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setisSticky(true);
      } else {
        setisSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  // navitems array
  // const navItems = [
  //     { link: "Home", path: "home" },
  //     { link: "Service", path: "service" },
  //     { link: "About", path: "about" },
  // ];

  // navitems array from database
  useEffect(() => {
    setNavItems(
      companyProfileContent.filter(
        (items) => items.section === "header" && items.subsection === "menu"
      )
    );

    setNavFunDemo(
      companyProfileContent.filter(
        (items) => items.section === "header" && items.subsection === "menuDemo"
      )
    );
  }, [companyProfileContent]);

  return (
    <header className="w-full bg-white md:bg-transparent fixed top-0 left-0 right-0 z-50">
      <nav
        className={`py-4 lg:px-14 px-4 ${
          isSticky
            ? "sticky top-0 left-0 right-0 border-b bg-white duration-300"
            : ""
        }`}
      >
        <div className="flex justify-between items-center text-base gap-8">
          <a
            href=""
            className="text-2xl font-semibold flex items-center space-x-3 hover:no-underline"
          >
            {" "}
            <img src={logo} alt="" className="w-14 inline-block items-center" />
            <span className="text-brandSecondary">
              Mudah
              <span className="text-brandPrimary">Technology</span>
            </span>
          </a>

          {/* nav items large devices */}
          <ul className="md:flex space-x-12 hidden">
            {navItems.map(({ value, value_1 }) => (
              <ScrollLink
                to={value_1}
                spy={true}
                smooth={true}
                offset={-100}
                key={value_1}
                className="block cursor-pointer text-base text-gray-900 hover:text-brandPrimary hover:no-underline first:font-medium"
              >
                {value}
              </ScrollLink>
            ))}

            {navFunDemo.map(({ value, value_1 }) => (
              <Link
                to={value_1}
                spy={true}
                smooth={true}
                offset={-100}
                key={value_1}
                className="block cursor-pointer text-base text-gray-900 hover:text-brandPrimary hover:no-underline first:font-medium"
              >
                {value}
              </Link>
            ))}
          </ul>

          {/* btn for large */}
          <div className="space-x-12 hidden lg:flex items-center">
            <button className="bg-brandPrimary py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray">
              <Link className="text-white" to="/login">
                Sign in
              </Link>
            </button>
          </div>

          {/* btn for mobile devices */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-neutralDGray focus:outline-none focus:text-gray-500"
            >
              {isMenuOpen ? (
                <FaXmark className="h-6 w-6" />
              ) : (
                <FaBars className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* nav items for mobile */}
        <motion.div
          className={`space-y-4 px-4 mt-16 py-7 bg-neutralDGray opacity-80  duration-700 ${
            isMenuOpen ? "block fixed top-0 right-0 left-0 z-50" : "hidden"
          }`}
        >
          {/* {navItems.map(({ link, path }) => (
            <Link
              to={path}
              spy={true}
              smooth={true}
              offset={-100}
              key={path}
              className="block text-base text-white hover:text-brandPrimary first:font-medium"
            >
              {link}
            </Link>
          ))} */}

          {navItems.map(({ value, value_1 }) => (
            <ScrollLink
              to={value_1}
              spy={true}
              smooth={true}
              offset={-100}
              key={value_1}
              className="block cursor-pointer text-base text-brandSecondary hover:text-brandPrimary hover:no-underline first:font-medium border-b-2 border-blue-200 duration-300"
            >
              {value}
            </ScrollLink>
          ))}
          {navFunDemo.map(({ value, value_1 }) => (
            <Link
              to={value_1}
              spy={true}
              smooth={true}
              offset={-100}
              key={value_1}
              className="block cursor-pointer text-base text-brandSecondary hover:text-brandPrimary hover:no-underline first:font-medium border-b-2 border-blue-200"
            >
              {value}
            </Link>
          ))}
        </motion.div>
      </nav>
    </header>
  );
};

export default PublicHeader;
