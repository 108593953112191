import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewAitoolMutation } from "./aitoolsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewAitoolForm = ({ users }) => {

    const [errorAi, setErrorAi] = useState(null);

    const [addNewAitool, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewAitoolMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState("Tool Title")
    const [aiprompt, setAiprompt] = useState("Help me to create POC Document ... (continue)")
    const [content, setContent] = useState("Strucure of document at least contain \ntitle \nsubsection")
    const [weight, setWeight] = useState("0/0")
    const [lastresponse, setLastresponse] = useState("Click blue button to see AI Response")
    const [gptmodel, setGptmodel] = useState("GPT 3.5 turbo")
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setAiprompt('')
            setContent('')
            setWeight('')
            setLastresponse('')
            setGptmodel('')
            setUserId('')
            navigate('/dash/aitools')
        }
    }, [isSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onAipromptChanged = e => setAiprompt(e.target.value)
    const onContentChanged = e => setContent(e.target.value)
    const onWeightChanged = e => setWeight(e.target.value)
    const onLastresponseChanged = e => setLastresponse(e.target.value)
    const onGptmodelChanged = e => setGptmodel(e.target.value)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, aiprompt, content, weight, lastresponse, gptmodel, userId].every(Boolean) && !isLoading

    const onSaveAitoolClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewAitool({ user: userId, title, aiprompt, content, weight, lastresponse, gptmodel })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const onRunAiResponseClicked = async (e) => {
        // direct call API saja dari sini , hard code

        e.preventDefault(); // Prevent the form from submitting and reloading the page

        alert("button clicked")

        const payload = {
                "reqAiPrompt" : aiprompt, 
                "reqContent" : content, 
                "reqWeight" : weight
                }

        try {
            const response = await fetch('https://mudahtechcomproapi.onrender.com/gptmodel/gpt35', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const data = await response.json();
            setLastresponse(data);
            alert("AI Response Arrived!" + JSON.stringify(data));
            } catch (error) {
                setErrorAi(error.message);
            }


      };

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !aiprompt ? "form__input--incomplete" : ''

    const componentcontent = (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <div className="mb-2 pl-4 pt-2 pb-2 text-lg bg-blue-400 text-gray-900 font-bold">
                <strong className='text-gray-700'>New AI Tool</strong>
            </div>
            <form className="w-full space-y-5 border border-gray-100" onSubmit={onSaveAitoolClicked}>
                <div className="flex items-center border border-gray-300">
                    <div className="">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> SAVE
                        </button>
                    </div>
                </div>
                
                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        Title:</label>
                    <textarea
                        className="border border-gray-500 w-[90%] rounded-md"
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        rows={1}
                        value={title}
                        onChange={onTitleChanged}
                    />
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        AI Prompt:</label>
                    <textarea
                        className="border border-gray-500 w-[90%] rounded-md"
                        id="aiprompt"
                        name="aiprompt"
                        type="text"
                        autoComplete="off"
                        rows={5}
                        value={aiprompt}
                        onChange={onAipromptChanged}
                    />
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        Content:</label>
                    <textarea
                        className="border border-gray-500 w-[90%] rounded-md"
                        id="content"
                        name="content"
                        type="text"
                        autoComplete="off"
                        rows={10}
                        value={content}
                        onChange={onContentChanged}
                    />
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        Weight:</label>
                    <textarea
                        className="border border-gray-500 w-[90%] rounded-md"
                        id="weight"
                        name="weight"
                        type="text"
                        autoComplete="off"
                        rows={1}
                        value={weight}
                        onChange={onWeightChanged}
                    />
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        Last Response:</label>
                    <textarea
                        className="border border-gray-300 w-[80%] rounded-md"
                        id="lastresponse"
                        name="lastresponse"
                        type="text"
                        autoComplete="off"
                        rows={10}
                        value={lastresponse}
                        onChange={onLastresponseChanged}
                    />
                    <div className="p-5">
                        <button
                            className="border border-blue-600 bg-blue-400 rounded-md text-blue-900 py-2 px-4 rounded shadow-lg transform transition-transform hover:translate-y-0.5 hover:shadow-xl"
                            onClick={onRunAiResponseClicked}
                        >
                            Run AI Response
                        </button>
                    </div>
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="title">
                        GPT Model:</label>
                        <select
                            id="gptmodel"
                            name="gptmodel"
                            className="border border-gray-500 w-[20%] rounded-md"
                            value={gptmodel}
                            onChange={onGptmodelChanged}
                        >
                            <option>GPT 3.5 turbo</option>
                            <option>GPT 4</option>
                    </select>
                </div>

                <div className="flex items-center border border-gray-300">
                    <label className="pl-5 w-[10%]" htmlFor="username">
                        Saved by:</label>
                    <select
                        id="username"
                        name="username"
                        className="border border-gray-500 w-[20%] rounded-md"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>

            </form>
        </>
    )

    return componentcontent
}

export default NewAitoolForm