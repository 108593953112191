const Homeadmin = () => {
  const menuItems = [
    { label: 'New Chitose', icon: '🔍', hyperlink: 'https://appchitose.onrender.com/'},
    { label: 'SDG Financing Hub AI', icon: '📡', hyperlink: 'https://www.mindmeister.com/' },
    { label: 'OtoKreasi Website', icon: '⚙️', hyperlink: 'https://www.mindmeister.com/' },
    { label: 'Instagram OtoKreasi', icon: '🤖', hyperlink: 'https://www.mindmeister.com/' },
    { label: 'Web Admin OtoKreasi', icon: '⚡', hyperlink: 'https://www.mindmeister.com/' },
    { label: 'https://www.mindmeister.com', icon: '➕', hyperlink: 'https://www.mindmeister.com/app/folders' },
    { label: 'Jagoan Hosting', icon: '➕', hyperlink: 'https://www.mindmeister.com/app/folders' },
    { label: 'Kledo', icon: '➕', hyperlink: 'https://www.mindmeister.com/app/folders' },
    { label: 'Azure Billing', icon: '➕', hyperlink: 'https://portal.azure.com/?feature.tokencaching=true&feature.internalgraphapiversion=true#view/Microsoft_Azure_GTM/ModernBillingMenuBlade/~/Overview' },
    { label: 'Power BI', icon: '➕', hyperlink: 'https://www.mindmeister.com/app/folders' },
    { label: 'Software AG Partner', icon: '➕', hyperlink: 'https://www.mindmeister.com/app/folders' },
    { label: 'Mongodb Atlas', icon: '➕', hyperlink: 'https://cloud.mongodb.com/v2/6546b62dd306f82468c12036#/overview' },
    { label: 'OnRender', icon: '➕', hyperlink: 'https://dashboard.render.com/' },
    { label: 'Chat GPT', icon: '➕', hyperlink: 'https://cloud.mongodb.com/v2/6546b62dd306f82468c12036#/overview' },
    { label: 'Open AI', icon: '➕', hyperlink: 'https://cloud.mongodb.com/v2/6546b62dd306f82468c12036#/overview' }
];

return (
    <main>
        <div className="bg-blue-400 text-gray-900 font-bold p-4 flex justify-between items-center">
            <header className="text-xl font-semibold text-white">
                Mudah Technology Portal
            </header>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-4">
            {menuItems.map((item, index) => (
                <div key={index} className="bg-gradient-to-r from-blue-500 to-green-500 text-white p-6 rounded-lg shadow-md flex items-center justify-center">
                    <div className="flex flex-col items-center">
                        <div className="icon-container mb-2">
                            {/* Replace this with actual icons */}
                            <span className="text-3xl">{item.icon}</span>
                        </div>
                        <div className="text-center text-lg font-semibold">
                        <a href={item.hyperlink} target="_blank" rel="noopener noreferrer" className="text-white">
                            {item.label}
                        </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </main>
)

};

export default Homeadmin;
