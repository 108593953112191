import { useState, useEffect } from "react"
import { useUpdateComproitemMutation, useDeleteComproitemMutation } from "./companyprofileApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"

const EditComproitemForm = ({ comproitem, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateComproitem, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateComproitemMutation()

    const [deleteComproitem, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteComproitemMutation()

    const navigate = useNavigate()

    // create const sebanyak field yang mau di update
    const [section, setSection] = useState(comproitem.section)
    const [subsection, setSubsection] = useState(comproitem.subsection)
    const [value, setValue] = useState(comproitem.value)
    const [value_1, setValue_1] = useState(comproitem.value_1)
    const [value_2, setValue_2] = useState(comproitem.value_2)
    const [description, setDescription] = useState(comproitem.description)
    const [order, setOrder] = useState(comproitem.order)
    const [status, setStatus] = useState(comproitem.status)

    // const [userId, setUserId] = useState(comproitem.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setSection('')
            setSubsection('')
            setValue('')
            setValue_1('')
            setOrder('')
            setStatus('')
            navigate('/dash/homecompanyprofile/companyprofilelist')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onSectionChanged = e => setSection(e.target.value)
    const onSubsectionChanged = e => setSubsection(e.target.value)
    const onValueChanged = e => setValue(e.target.value)
    const onValue_1Changed = e => setValue_1(e.target.value)
    const onValue_2Changed = e => setValue_2(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onOrderChanged = e => setOrder(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)
    // const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [section, subsection, value, value_1, order, status].every(Boolean) && !isLoading

    const onSaveComproitemClicked = async (e) => {
        if (canSave) {
            await updateComproitem({ id: comproitem.id, section, subsection, value, value_1, value_2, description, order, status })
        }
    }

    const onDeleteComproitemClicked = async () => {
        await deleteComproitem({ id: comproitem.id })
    }

    const created = new Date(comproitem.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(comproitem.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !section ? "form__input--incomplete" : ''
    const validTextClass = !subsection ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="icon-button"
                title="Delete"
                onClick={onDeleteComproitemClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </button>
        )
    }

    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>Edit Comproitem #{comproitem.ticket}</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            onClick={onSaveComproitemClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        {deleteButton}
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-title">
                                    section:
                                </label>
                            </td>
                            <td>
                                <input
                                    className={`form__input ${validTitleClass}`}
                                    id="comproitem-title"
                                    name="title"
                                    type="text"
                                    autoComplete="off"
                                    value={section}
                                    onChange={onSectionChanged}
                                />                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    subsection:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="text"
                                    value={subsection}
                                    onChange={onSubsectionChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    Value:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value"
                                    value={value}
                                    onChange={onValueChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    value_1:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value_1"
                                    value={value_1}
                                    onChange={onValue_1Changed}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    value_2:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="value_2"
                                    value={value_2}
                                    onChange={onValue_2Changed}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                description:
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="description"
                                    value={description}
                                    onChange={onDescriptionChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    order:
                                    <div><small>Nilai integer untuk urutan tampil</small></div>
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="order"
                                    value={order}
                                    onChange={onOrderChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="form__label" htmlFor="comproitem-text">
                                    status:
                                    <div><small>0=hide</small></div>
                                    <div><small>1=show</small></div>
                                </label>
                            </td>
                            <td>
                                <textarea
                                    className={`form__input form__input--text ${validTextClass}`}
                                    id="comproitem-text"
                                    name="text"
                                    value={status}
                                    onChange={onStatusChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="form__divider">
                    <p className="form__created">Created:<br />{created}</p>
                    <p className="form__updated">Updated:<br />{updated}</p>
                </div>

            </form>
        </>
    )

    return content
}

export default EditComproitemForm